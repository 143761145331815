<template>
    <div class="routes-panel">
        <span v-for="(item, index) in routeList"  :key="index"
            @click="goBack(index - routeList.length + 1)"
        >
            {{item.meta.title || '-'}}
        </span>

    </div>
</template>

<script>
import {STORAGE_KEY} from "@/constant";

/*function disabledPopState(e) {
    console.log(e);
    history.go(1);
}*/

export default {
    name: "CSBreadcrumb",
    created() {
        let routeList = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY));
        this.routeList = routeList.filter(r => r.meta.title);
        // window.addEventListener("popstate", disabledPopState);
    },
    data() {
      return {
          routeList: [],
      }
    },
    methods: {
        /**
         * 导航后退
         * @param {Number} num 后退的页面数
         * */
        goBack(num) {
            if (num === 0) {
                return ;
            }
            // window.removeEventListener('popstate', disabledPopState);
            let routerHistory = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY)) || [];
            routerHistory.splice(routerHistory.length - Math.abs(num), Math.abs(num));
            sessionStorage.setItem(STORAGE_KEY.ROUTE_HISTORY, JSON.stringify(routerHistory));
            this.$router.go(num);
        }
    }
}
</script>

<style scoped lang="stylus">
.routes-panel
    background #FFFFFF
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    font-size 24px
    line-height 1
    padding 18px 34px
    margin-bottom 20px
    span
        display inline-block
        position relative
        vertical-align middle
        color #000
        cursor pointer
        &:not(:last-of-type)
            margin-right 34px
            color #999
            &::after
                width 13px
                height 13px
                border-top 2px solid #999
                border-right 2px solid #999
                content " "
                position absolute
                top 50%
                right -18px
                transform translateY(-50%) rotate(45deg)
</style>
